<template>
  <div class="center" style="overflow: auto">
    <!--列表label-->
    <head-layout
      head-title="安全标识设置记录"
      :head-btn-options="headBtnOptions"
      @head-remove="handleDelete"
      @head-add="headAdd"
    ></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :head-title="this.$t('cip.plat.sync.project.title.indexHeadTitle')"
      :table-options="option"
      :table-data="data"
      :table-loading="loading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="selectionChange"
    >
      <template slot="picList" slot-scope="scope">
        <!-- v-if="scope.row.picList.length > 0 && scope.row.picList[0] != ''" -->
        <!-- v-if="scope.row.picList.length > 0" -->
        <!-- :class="picListImg" -->
        <el-image
          v-if="scope.row.picList"
          :key="scope.row.picList[0]"
          :src="scope.row.picList[0]"
          :fit="fit"
          style="width: 100%; height: 130px"
          :preview-src-list="scope.row.picList.map((val) => val)"
        ></el-image>
        <!-- {row.createUser + userInfo.user_id} -->
      </template>
      <!-- </div> -->
      <template #customBtn="{ row }">
        <template>
          <el-button type="text" size="small" @click="rowView(row)">
            查看
          </el-button>
        </template>
        <!-- <template v-if="row.status == '1'"> -->
        <el-button
          v-if="row.status == '1' && row.createUser == userInfo.user_id"
          type="text"
          size="small"
          @click="rowEdit(row)"
        >
          编辑
        </el-button>
        <el-button
          v-if="row.status == '1' && row.createUser == userInfo.user_id"
          type="text"
          size="small"
          @click="rowDel(row)"
        >
          删除
        </el-button>
        <!-- </template> -->
      </template>
    </grid-layout>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { getRecordList, remove } from "@/api/siteManagement/safetySigns";
import { mapGetters } from "vuex";

export default {
  components: { HeadLayout, GridLayout },
  data() {
    return {
      data: [],
      form: {},
      querys: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      selectionList11: [],
      selectionList12: [],
      option: {
        // rowKey: "id",
        height: "auto",
        border: true,
        index: true,
        indexLabel: "序号",
        menuFixed: "right", //操作列冻结
        menuWidth: 180,
        column: [
          {
            label: "现场图片",
            prop: "picList",
            align: "center",
            slot: true,
            width: 250,
          },
          {
            label: "设置位置",
            prop: "location",
            align: "left",
            overHidden: true,
            // width: 400,
          },
          {
            label: "标识清单",
            prop: "siList",
            align: "center",
            overHidden: true,
          },
          {
            label: "设置时间",
            prop: "setDate",
            align: "center",
            overHidden: true,
            width: 180,
          },
          {
            label: "状态",
            prop: "status",
            dataType: "number",
            align: "center",
            width: 120,
            dicData: [
              { label: "草稿", value: "1" }, // draft 草稿
              { label: "已确认", value: "2" }, // confirmed 已确认
            ],
            props: {
              label: "label",
              value: "value",
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),

    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "设置位置",
          prop: "location",
          span: 4,
          clearable: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "设置位置",
        },
        {
          label: "标识名称",
          prop: "signName",
          span: 4,
          clearable: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "标识名称",
        },
        {
          label: "设置时间",
          prop: "setDate",
          type: "daterange",
          format: "yyyy-MM-dd",
          clearable: true,
          span: 4,
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期",
        },
      ];
    },
    //头按钮
    headBtnOptions() {
      let buttonBtn = [];
      // 新增
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.addBtn"),
        emit: "head-add",
        btnOptType: "add",
      });
      // 删除
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-remove",
        btnOptType: "remove",
      });
      return buttonBtn;
    },
  },
  methods: {
    onLoad(page) {
      // , params = {}
      this.page = page;
      this.loading = true;
      let params = {
        // current: this.inoutPage.currentPage,
        // size: this.inoutPage.pageSize,
        ...this.querys,
      };
      delete params.setDate;

      getRecordList(page.currentPage, page.pageSize, params).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        // this.page.total =
        //   data == undefined || data.total == undefined ? 0 : data.total;
        // this.$refs.gridLayOut.page.total = this.page.total;
        // this.data = data.records;
        for (let index in res.data.data.records) {
          res.data.data.records[index].picList =
            res.data.data.records[index].picList.split(",");
          res.data.data.records[index].siList = res.data.data.records[
            index
          ].bsSignList
            .map((item) => {
              return item.signName;
            })
            .toString();
        }
        this.data = data.records;
        this.loading = false;
        this.selectionList = [];
        // //重新渲染表格，解决勾选框错行问题
        // this.$nextTick(() => {
        //   this.$refs.gridLayout.$refs.grid.$refs.table.doLayout();
        // });
      });
      // this.mounted();
    },
    // 搜索
    gridHeadSearch(query) {
      console.log(query, "query");
      this.page.currentPage = 1;
      if (query.setDate) {
        query.startTime = query.setDate[0] + " 00:00:00";
        query.endTime = query.setDate[1] + " 23:59:59";
      }
      this.querys = query;
      console.log(this.querys, "this.querys");

      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty() {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.querys = {};
      this.onLoad(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
      // this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    // 行按钮 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/siteManagement/safetySigns/edit`,
        query: {
          id: row.id,
          // data: data,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/siteManagement/safetySigns/view`,
        query: {
          id: row.id,
          // data: data,
          type: "view",
        },
      });
    },
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        remove(row.id).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    // 头按钮 新增
    headAdd() {
      this.$router.push({
        path: `/siteManagement/safetySigns/add`,
        query: {
          type: "add",
        },
      });
    },
    // 头按钮 删除
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        this.selectionList11 = this.selectionList.filter((res) => {
          return res.status == 2;
        });
        if (this.selectionList11.length != 0) {
          this.$message.warning("状态已确认，不可删除！");
          return;
        }
        this.selectionList12 = this.selectionList.filter((res) => {
          return res.createUser != this.userInfo.user_id;
        });
        if (this.selectionList12.length != 0) {
          this.$message.warning("不是创建者，不可删除！");
          return;
        }
        remove(this.ids).then((res) => {
          if (res.data.code == 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
  },
  // 初始化数据
  mounted() {
    this.onLoad(this.page);
  },

  created() {
    // this.onLoad(this.page);
  },
};
</script>

<style scoped>
/* ::v-deep .el-table_bode-wrapper{

}
.center {
  overflow: auto;
  overflow-x: hidden;
}
.picListImg {
  width: 180px;
  padding: 5px;
} */
</style>
