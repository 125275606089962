var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center", staticStyle: { overflow: "auto" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "安全标识设置记录",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-remove": _vm.handleDelete, "head-add": _vm.headAdd },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "head-title": this.$t("cip.plat.sync.project.title.indexHeadTitle"),
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.handleCurrentChange,
          "page-size-change": _vm.handleSizeChange,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
        },
        scopedSlots: _vm._u([
          {
            key: "picList",
            fn: function (scope) {
              return [
                scope.row.picList
                  ? _c("el-image", {
                      key: scope.row.picList[0],
                      staticStyle: { width: "100%", height: "130px" },
                      attrs: {
                        src: scope.row.picList[0],
                        fit: _vm.fit,
                        "preview-src-list": scope.row.picList.map((val) => val),
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.rowView(row)
                        },
                      },
                    },
                    [_vm._v("\n          查看\n        ")]
                  ),
                ],
                row.status == "1" && row.createUser == _vm.userInfo.user_id
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowEdit(row)
                          },
                        },
                      },
                      [_vm._v("\n        编辑\n      ")]
                    )
                  : _vm._e(),
                row.status == "1" && row.createUser == _vm.userInfo.user_id
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDel(row)
                          },
                        },
                      },
                      [_vm._v("\n        删除\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }